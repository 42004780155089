// You can add global styles to this file, and also import other style files
@import "~@ng-select/ng-select/themes/default.theme.css";

// Variables
@import "global_styles/openproject/_variable_defaults.scss";
//@import "global_styles/openproject/_variables_deprecated.scss";
// Foundation variables
@import "global_styles/openproject/_settings.scss";

//  Dependencies
@import "global_styles/vendor/foundation-apps/scss/foundation.scss";

// Core styles
@import "global_styles/openproject.sass";

// AG Grid table css
@import "ag-grid-community/dist/styles/ag-grid.css";
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
.hide-my-group {
  color:black;
}

.my-class-3 {
  font-size: larger;
  color: green;
  color: blue;
}
.my-class-2 {
  font-size: larger;
  color: red;
  color: blue;
}
.my-class-1 {
  font-size: larger;
  color: blue;
}


.upsale,
.-ee-upsale{
  display: none !important;
}

.op-app-footer {
  height: 5vh !important;
}
