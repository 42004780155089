//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2022 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

$columns--background-color: $gray-light !default
$columns--border-color: $gray !default

%columns--container
  background-color: $columns--background-color
  border: 1px solid $columns--border-color

  legend
    @extend .hidden-for-sighted

%columns-grid
  display:          grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  grid-gap:         10px 40px
  list-style-type:  none
  margin:           10px 0 0 0

.simple-columns--container
  @extend %columns--container
  padding: 1rem 1rem 1rem 1rem
  margin: 0.6em 0
  position: relative

  .simple-column--trailing-labels
    @extend %columns-grid
    grid-column: 1 / -1

    > .-trailing-label.form--field
      grid-column: 1 / -1
      margin-bottom: 0

  &.collapsed
    display: none

  .simple-columns--close
    position: absolute
    top: 5px
    right: 5px
    width: 1rem
    z-index: 2

.simple-columns--columns
  @extend %columns-grid

  > li:not(.simple-columns--controls)
    display: grid
    grid-template-columns: repeat(auto-fill, 50%)
    align-items: center

    button,
    .button
      margin: 0 10px 10px 0

  .simple-columns--column.-with-radio-buttons
    grid-template-columns: 10% repeat(auto-fit, minmax(45%, 1fr))
    grid-template-rows: repeat(auto-fill, 35px)
    grid-gap: 10px 0

    .simple-columns--column-name.form--label
      grid-column: 2

  .simple-columns--controls
    grid-column: 1 / -1
    margin-top: 1rem

  .simple-columns--column-name
    @include text-shortener
    margin: auto 0

@include breakpoint(680px down)
  .simple-columns--column:not(.-with-radio-buttons)
    .simple-columns--column-value
      grid-column: 1 / -1
