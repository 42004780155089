.op-logo
  width: 230px
  height: var(--header-height)

  &-header--link, &-footer--link
    width: 100%
    display: block
    height: auto
    background: var(--header-home-link-bg)
    text-indent: -9999em
    background-position: center
    background-size: contain
    background-repeat: no-repeat

  @include breakpoint(850px down)
    display: none

